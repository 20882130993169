import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import LatestYoutubeVideo from '../components/LatestYoutubeVideo'
import PageHeader from '../components/PageHeader'
import PageSpace from '../components/PageSpace'
import ServiceSection from '../components/ServiceSection'
import ParagraphText from '../components/typography/ParagraphText'
import { RegularTitle, SectionTitle } from '../components/typography/Title'

function AboutPage() {
    return (
        <PageSpace top={100} bottom={100}>
            <PageHeader 
            title={"About Us"} 
            description={"\
            Better software means a better life. Listening to you and providing software responses. We are the ones who make it happen."} 
            className=""
            >
            
            
        </PageHeader>
        <br/>
        <div className='container gradient-container' 
        >
        <ParagraphText style={{color: "white"}}>
        We are a software development company from DRC and we are passionate about delivering fast, 
        robust software solutions to our clients .At Auctux , we like to stay flexible so that you don't spend month or years on clunky 
        software that will waste you time. we specialize in doing our jobs fast and well. We specialize in so many field such as making 
        web applications, desktop applications, Games, phone applications and coming up with solutions for software.
        </ParagraphText>
        </div>

        <RegularTitle style={{textAlign: 'center'}}>Our services</RegularTitle>
        <div className='container'>
        <ServiceSection/>
        </div>
        <LatestYoutubeVideo about={true} />
        <br/>

        <div>
            <RegularTitle style={{textAlign: 'center'}}>Download Our Games</RegularTitle>
            <br/>
            <div className='container'>
            <ParagraphText style={{textAlign: 'center'}}>
                These Games are our submissions for GameJams that we participated in. Go ahead and try them and let us know what you think of them ✌️
            </ParagraphText>
            </div>
            <div className='gameContainer'>
                <div className='game_card'>
                    <div className='game_image_container'>
                    <StaticImage className="game__image" src="../images/game1.png" alt="game1image" placeholder="blurred" objectPosition="50% 30%"/>
                    </div>
                    <p>You Can't controll tom (Made in 48 hrs)</p>
                    <p style={{color: "rgba(255, 255, 255, 0.6)", textTransform: "capitalize", fontSize: "15px"}}>🪟 available on windows</p>
                    <br/>
                    <a className='download-button' href="https://auctux.itch.io/you-cant-control-tom" target="_blank">
                        Download
                    </a>
                </div>
                <div className='game_card'>
                    <div className='game_image_container'>
                    <StaticImage className="game__image" src="../images/game2.png" alt="game1image" placeholder="blurred" objectPosition="50% 30%"/>
                    </div>
                    <p>Dash Race (Made in 48 hrs)</p>
                    <p style={{color: "rgba(255, 255, 255, 0.6)", textTransform: "capitalize", fontSize: "15px"}}>🪟 available on windows</p>

                    <br/>
                    <a className='download-button' href="https://auctux.itch.io/dash-race" target="_blank">
                        Download
                    </a>
                </div>
                
            </div>
        </div>

        </PageSpace>
    )
}

export default AboutPage
